<template>
  <div>
    <vx-card title="Imran">
      <vs-row style="margin:10px;">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="right" vs-w="1"></vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="right" vs-w="1">
          <strong>Today</strong>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="left" vs-w="2">
          <strong>YESTERDAY</strong>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <strong>WTD</strong>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <strong>MTD</strong>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <strong>QTD</strong>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <strong>YTD</strong>
        </vs-col>
        <vs-row style="margin:10px;">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
            <strong>Enrollment</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">300</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">25</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">28</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">19</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">41</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">22</vs-col>
        </vs-row>
        <vs-row style="margin:10px;">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
            <strong>Visitor</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">300</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">25</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">28</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">19</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">41</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">22</vs-col>
        </vs-row>
        <vs-row style="margin:10px;">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
            <strong>Utilization</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">300</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">25</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">28</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">19</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">41</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">22</vs-col>
        </vs-row>
      </vs-row>
      <vs-divider />
      <vs-row>
        <h4>Delays</h4>
        <vs-row style="margin:10px;">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>P1</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>P2</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>P3</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
        </vs-row>
        <vs-row style="margin: 9px">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <span
              class
              style="background: red;border-radius: 5px;font-color: white;color: black;"
            >47</span>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <span
              class
              style="background: red;border-radius: 5px;font-color: white;color: black;"
            >333</span>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <span
              class
              style="background: red;border-radius: 5px;font-color: white;color: black;"
            >386</span>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
        </vs-row>
      </vs-row>
      <vs-divider />
      <vs-row>
        <h4>Missed Hot Pockets</h4>
        <vs-row style="margin:10px;">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>Net Enquery</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>Visitor</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>Enrollment</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>Loan</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>Reference</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>CR</strong>
          </vs-col>
        </vs-row>
        <vs-row style="margin:10px;">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <span
              class
              style="background: red;border-radius: 5px;font-color: white;color: black;"
            >74</span>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <span
              class
              style="background: red;border-radius: 5px;font-color: white;color: black;"
            >45</span>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <span
              class
              style="background: red;border-radius: 5px;font-color: white;color: black;"
            >74</span>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <span
              class
              style="background: red;border-radius: 5px;font-color: white;color: black;"
            >841</span>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <span
              class
              style="background: red;border-radius: 5px;font-color: white;color: black;"
            >47</span>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <span
              class
              style="background: red;border-radius: 5px;font-color: white;color: black;"
            >52</span>
          </vs-col>
        </vs-row>
      </vs-row>
      <vs-divider />
      <vs-row>
        <h4>DUES</h4>
        <vs-row style="margin:10px;">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>CPA</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>CMA</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>CFA</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>FRM</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>USP</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>DA</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>RPA</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>FOF(O)</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
        </vs-row>
        <vs-row style="margin:10px;">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">74</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">45</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">74</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">841</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">47</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
        </vs-row>
      </vs-row>
      <vs-divider />
      <vs-row>
        <h4>SR%</h4>
        <vs-row style="margin:10px;">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>CPA</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>CMA</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>CFA</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>FRM</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>USP</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>DA</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>RPA</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
            <strong>FOF(O)</strong>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
        </vs-row>
        <vs-row style="margin:10px;">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">74</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">45</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">74</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">841</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">47</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2"></vs-col>
        </vs-row>
      </vs-row>
    </vx-card>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  }
  // methods: {
  //   spoc_name: function() {
  //     alert("hiiiii");
  //     this.$router.push("Bde_stats");
  //   }
  // }
};
</script>